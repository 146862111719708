<template>
  <b-row class="mb-1">
    <b-col cols="2">
      <b-form-input v-model="search.groupSearch" size="sm" placeholder="Suche nach Gruppe..."
                    @input="emitSearchChanged"/>
    </b-col>
    <b-col cols="2">
      <b-form-input v-model="search.contentSearch" size="sm" :placeholder="searchDesc"
                    @input="emitSearchChanged"/>
    </b-col>
    <b-col cols="4">
      <b-form-checkbox v-model="filterOptions.inactive" inline>
        <b-badge id="tt-inactive" class="mr-2" variant="primary">Inaktiv</b-badge>
        <b-tooltip target="tt-inactive" triggers="hover">Anzeige aller inaktiven Einträge</b-tooltip>
      </b-form-checkbox>
    </b-col>
    <b-col cols="auto" class="ml-auto">
      <b-button size="sm" variant="primary" class="mb-2" @click="emitFilterOptions">
        <b-icon-search/>
      </b-button>
    </b-col>
    <b-col cols="auto">
      <b-button variant="primary" size="sm" class="mb-2" @click="addGroup">Neue Gruppe</b-button>
    </b-col>
    <b-col cols="auto">
      <b-button variant="primary" size="sm" class="mb-2" @click="addGroupContent(null)">{{ buttonName }}</b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "GroupFilter",
  props: ['groupContent'],
  data() {
    return {
      search: {
        groupSearch: '',
        contentSearch: ''
      },
      filterOptions: {
        inactive: false,
      }
    }
  },
  methods: {
    addGroup() {
      this.$emit('add-group')
    },
    addGroupContent(data) {
      this.$emit('add-group-content', data)
    },
    emitSearchChanged() {
      this.$emit('search-changed', this.search)
    },
    emitFilterOptions() {
      this.$emit('filter-options', this.filterOptions)
    }
  },
  computed: {
    searchDesc() {
      switch(this.groupContent) {
        case 'software':
          return 'Suche nach Software...'
        case 'template':
          return 'Suche nach Template...'
        case 'contract':
          return 'Suche nach Vertrag...'
        default:
          return 'Suche nach...'
      }
    },
    buttonName() {
      switch(this.groupContent) {
        case 'software':
          return 'Neue Software'
        case 'template':
          return 'Neues Template'
        case 'contract':
          return 'Neuer Vertrag'
        default:
          return 'Neu'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
