<template>
  <b-card body-class="p-0" header-tag="header" class="shadow-sm"
          :header-class="[warning ? 'card-header-danger' : 'card-header']">
    <template #header>
      <div class="text-center noselect">{{ title }}</div>
    </template>
    <b-card-body class="p-0">
      <slot/>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "Tile",
  props: ['title', 'warning']
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/details/Tiles.scss';
</style>
