<template>
  <b-modal id="licenseGroupModal" :title="title" size="xl" @show="onShow">
    <b-form @submit.stop.prevent="">
      <b-form-row>
        <b-col cols="6">
          <label>Gruppenname:</label>
          <b-form-input v-model="groupName" placeholder="Gruppenname..." trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="2">
          <label>Warnhinweis: <b-icon-question-circle-fill id="tt-warning" :variant="iconVariant" class="header-icon"/></label>
          <b-form-input v-model="countWarning" number type="number" :disabled="this.infinite"/>
          <b-tooltip target="tt-warning" triggers="hover">Anzahl ab wann eine Warnung angezeigt wird</b-tooltip>
        </b-col>
        <b-col cols="2">
          <label>Maximum: <b-icon-question-circle-fill id="tt-max" :variant="iconVariant" class="header-icon"/></label>
          <b-form-input v-model="countMax" number type="number" :disabled="this.infinite"/>
          <b-tooltip target="tt-max" triggers="hover">Maximal erlaubte Anzahl an Lizenzen</b-tooltip>
        </b-col>
        <b-col cols="2">
          <label>Unbegrenzt: <b-icon-question-circle-fill id="tt-infinite" :variant="iconVariant" class="header-icon"/></label>
          <b-form-checkbox class="ml-1 mt-1" v-model="infinite" :value="true" :unchecked-value="false"/>
          <b-tooltip target="tt-infinite" triggers="hover">Lizenzzählung ohne Maximalwert</b-tooltip>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="4">
          <label>Lizenzart:</label>
          <b-form-select v-model="licenseManageSelect.selected" :options="licenseManageSelect.options">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Lizenzierung auswählen --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="4">
          <label>Lizenzabfrage:</label>
          <b-form-select v-model="licenseMethodSelect.selected" :options="licenseMethodSelect.options">
            <template #first>
              <b-form-select-option :value="null" disabled>-- Lizenzabfrage auswählen --</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col cols="4">
          <label>Lizenzportal:</label>
          <b-form-input v-model="licensePortal" placeholder="Lizenzportal..." trim/>
        </b-col>
        <b-col cols="4">
          <label>Lizenzserver:</label>
          <b-form-input v-model="licenseServer" placeholder="Lizenzserver..." trim/>
        </b-col>
      </b-form-row>
      <b-form-row class="mt-2">
        <b-col>
          <label>Notiz:</label>
          <b-form-textarea v-model="note" rows="4"/>
        </b-col>
      </b-form-row>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button v-if="method === 'edit' && !data.inactive" variant="outline-danger" class="mr-auto" @click="toggleGroup()">
        <b-spinner v-show="loadingActiveButton" small/>
        Gruppe inaktiv setzen
      </b-button>
      <b-button v-else-if="method === 'edit' && data.inactive" variant="outline-success" class="mr-auto"
                @click="toggleGroup()">
        <b-spinner v-show="loadingActiveButton" small/>
        Gruppe aktiv setzen
      </b-button>
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok" :disabled="disabledButton">
        <b-spinner v-show="loadingOkButton" small/>
        OK
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";

export default {
  name: "LicenseGroupModal",
  props: ['method', 'data'],
  data() {
    return {
      id: null,
      groupName: '',
      countMax: 0,
      countWarning: 0,
      infinite: false,
      licenseMethodSelect: {
        selected: null,
        options: [
          {value: 'portal', text: 'Portal'},
          {value: 'local', text: 'Lokale Datei'},
          {value: 'server', text: 'Lizenzserver'},
          {value: 'key', text: 'Lizenzschlüssel'}
        ]
      },
      licenseManageSelect: {
        selected: null,
        options: [
          {value: 'portal', text: 'Portal'},
          {value: 'file', text: 'Datei'},
          {value: 'key', text: 'Lizenzschlüssel'}
        ]
      },
      licensePortal: '',
      licenseServer: '',
      note: '',
      loadingOkButton: false,
      loadingActiveButton: false
    }
  },
  watch: {
    method() {
      this.onShow()
    },
    data() {
      this.onShow()
    }
  },
  methods: {
    ok() {
      switch(this.method) {
        case 'edit':
          this.updateGroup();
          break;
        case 'add':
          this.addGroup();
          break;
      }
    },
    onShow() {
      if(this.method === 'edit' && this.data) {
        this.id = this.data.id
        this.groupName = this.data.displayname
        this.countWarning = this.data.count_warning
        this.countMax = this.data.count_max
        this.infinite = this.data.infinite
        this.licenseMethodSelect.selected = this.data.license_method
        this.licenseManageSelect.selected = this.data.license_manage
        this.licensePortal = this.data.license_portal
        this.licenseServer = this.data.license_server
        this.note = this.data.note
      }
      else {
        this.id = null
        this.groupName = ''
        this.countMax =  0
        this.countWarning = 0
        this.infinite = false
        this.licenseMethodSelect.selected = null
        this.licenseManageSelect.selected = null
        this.licensePortal = ''
        this.licenseServer = ''
        this.note = ''
      }
    },
    refresh() {
      this.$emit('refresh');
    },
    async addGroup() {
      this.loadingOkButton = true
      let data = {
        displayname: this.groupName,
        count_max: this.countMax,
        count_warning: this.countWarning,
        infinite: this.infinite,
        license_manage: this.licenseManageSelect.selected,
        license_method: this.licenseMethodSelect.selected,
        license_portal: this.licensePortal,
        license_server: this.licenseServer,
        note: this.note
      }
      await putRequest('licenses/fg/group/add', data, this, 'Neuer Eintrag wurde erfolgreich erstellt.', 'Neuer Eintrag konnte nicht erstellt werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('licenseGroupModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })
    },
    async updateGroup() {
      this.loadingOkButton = true
      let data = {
        group_id: this.id,
        displayname: this.groupName,
        count_max: this.countMax,
        count_warning: this.countWarning,
        infinite: this.infinite,
        license_manage: this.licenseManageSelect.selected,
        license_method: this.licenseMethodSelect.selected,
        license_portal: this.licensePortal,
        license_server: this.licenseServer,
        note: this.note
      }
      await putRequest('licenses/fg/group/update', data, this, 'Der Eintrag wurde erfolgreich aktualisiert.', 'Der Eintrag konnte nicht aktualisiert werden.')
          .then(() => {
            this.loadingOkButton = false
            this.refresh()
            this.$bvModal.hide('licenseGroupModal')
          })
          .catch(() => {
            this.loadingOkButton = false
          })

    },
    async toggleGroup() {
      this.loadingActiveButton = true
      await putRequest('licenses/fg/group/inactive-toggle/' + this.data.id, null, this, 'Der Eintrag wurde erfolgreich umgesetzt.', 'Der Eintrag konnte nicht umgesetzt werden.')
          .then(() => {
            this.loadingActiveButton = false
            this.refresh()
            this.$bvModal.hide('licenseGroupModal')
          })
          .catch(() => {
            this.loadingActiveButton = false
          })
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    ...mapGetters(['userColorAccessibility']),
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'white'
    },
    title() {
      if(this.method === 'edit') {
        return 'Gruppe ändern'
      }
      return 'Gruppe hinzufügen'
    },
    disabledButton() {
      return !(this.groupName && this.licenseManageSelect.selected && this.licenseMethodSelect.selected)
    },
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
