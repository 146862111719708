<template>
  <b-card body-class="p-0" header-tag="header" class="shadow-sm">
    <template #header>
      <div class="text-center noselect">
        {{ group.displayname }}
        <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
      </div>
    </template>
    <b-card-body v-if="expand" class="pt-3 pb-2">
      <b-row>
        <b-col cols="4">
          <b-row class="mb-2">
            <b-col >
              <BadgeCounterGroup :group="group"/>
            </b-col>
            <b-col cols="auto" class="ml-auto">
              <b-icon-pencil-square :id="'tt-editGroup-' + group.id" font-scale="1.2" class="clickable mr-2"
                                    @click="editGroup(group)"/>
              <b-icon-plus-circle :id="'tt-addGroupContent-' + group.id" font-scale="1.2" class="clickable"
                                  @click="addGroupContent(group)"/>
              <b-tooltip :target="'tt-editGroup-' + group.id" triggers="hover">Bearbeiten</b-tooltip>
              <b-tooltip :target="'tt-addGroupContent-' + group.id" triggers="hover">Inhalt hinzufügen</b-tooltip>
            </b-col>
          </b-row>
          <slot name="groupInfo"/>
        </b-col>
        <b-col cols="8" class="ml-auto">
          <slot name="content"/>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import BadgeCounterGroup from "@/components/fg-software/badgeCounter/BadgeCounterGroup.vue";

export default {
  name: "Group",
  props: ['group', 'search'],
  components: {
    BadgeCounterGroup
  },
  data() {
    return {
      expand: true
    }
  },
  methods: {
    refresh() {
      this.$emit('refresh')
    },
    editGroup(data) {
      this.$emit('edit-group', data)
    },
    addGroupContent(data) {
      this.$emit('add-group-content', data.id)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
