<template>
  <b-badge v-if="group.infinite" :variant="variantSuccess">{{ group.count }}</b-badge>
  <b-badge v-else-if="group.count >= group.count_max" :variant="variantDanger">
    {{ group.count }}/{{ group.count_max }}
  </b-badge>
  <b-badge v-else-if="group.count >= group.count_warning" :variant="variantWarning">
    {{ group.count }}/{{ group.count_max }}
  </b-badge>
  <b-badge v-else-if="group.contracts" variant="neutral">{{ group.count }}</b-badge>
  <b-badge v-else :variant="variantSuccess">{{ group.count }}/{{ group.count_max }}</b-badge>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BadgeCounterGroup",
  props: ['group'],
  computed: {
    ...mapGetters(['userColorAccessibility']),
    variantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
    variantWarning() {
      if(this.userColorAccessibility === 'red-green') { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
